import * as React from 'react'

import { RouteObject } from 'react-router-dom'
import { EmployeeCardSettingsEntryPoint } from '../../../packages/EmployeeCardSettingsEntryPoint'
import { PortalProvider } from '@toasttab/ec-layout'

export const EntryRoute: RouteObject = {
  path: '/mvc/:client/Company/Setup/PaycardSettings',
  element: <Entry />
}

function Entry() {
  return (
    <PortalProvider value='data-ec-ecs'>
      <EmployeeCardSettingsEntryPoint />
    </PortalProvider>
  )
}
