import { ApolloError } from '@apollo/client'
import { Maybe } from '../generated/gql/graphql'

export interface SdpGraphQLError {
  message: String
  sdpErrorCode: SdpGraphQLErrorCode
}

export enum SdpGraphQLErrorCode {
  CODE_MISSING = 'ERROR_CODE_MISSING',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  USER_FORBIDDEN = 'USER_FORBIDDEN',
  RESTAURANT_FORBIDDEN = 'RESTAURANT_FORBIDDEN'
}

export const isSdpGraphQLError = (obj: object): obj is SdpGraphQLError => {
  return (obj as SdpGraphQLError).sdpErrorCode !== undefined
}

export const parseApolloError = (
  error?: ApolloError
): Maybe<SdpGraphQLError> => {
  if (!error) return null

  return {
    message: error.message,
    sdpErrorCode: error.graphQLErrors.length
      ? (error.graphQLErrors[0].extensions?.code as SdpGraphQLErrorCode) ||
        SdpGraphQLErrorCode.CODE_MISSING
      : SdpGraphQLErrorCode.CODE_MISSING
  } as SdpGraphQLError
}
