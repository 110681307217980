import * as React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AuditHistory } from '..'
import { useTranslation } from '@local/translations'

export interface AuditDrawerProps {
  testId?: string | number
  isOpen: boolean
  onRequestClose: () => void
}

/**
 * AuditDrawer component
 */
export const AuditDrawer = (props: AuditDrawerProps) => {
  const { t } = useTranslation()
  return (
    <Modal
      size='xl'
      data-testId={props.testId}
      position='pin-right'
      isOpen={props.isOpen}
      onRequestClose={() => props.onRequestClose()}
    >
      <Modal.Header headingId='modal_heading'>
        {t('audit-drawer.header')}
      </Modal.Header>
      <Modal.Body>
        <AuditHistory />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='flex-grow sm:flex-none'
          variant='primary'
          onClick={() => props.onRequestClose()}
        >
          {t('audit-drawer.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
