import * as React from 'react'
import cx from 'classnames'
import { CardSelector } from '../CardSelector'
import { useUniqueId } from '@toasttab/buffet-utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import {
  HelperTextProps,
  LabeledHelperTextProps
} from '@toasttab/buffet-pui-text-base'
import {
  SelectionGroup,
  SelectionGroupProps
} from '@toasttab/buffet-pui-input-base'

export interface CardSelectorItem
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'size' | 'onChange'
  > {
  contents: React.ReactNode
  /**
   * Currently, all values become strings when they are applied as a value attribute
   * on the underlying html element. Please manage your own casting (to and from string)
   * or else take a look at solving  https://github.com/toasttab/buffet/issues/2275
   * */
  value: string
}

export interface CardSelectorGroupProps
  extends TestIdentifiable,
    HelperTextProps,
    LabeledHelperTextProps,
    Omit<SelectionGroupProps, 'children' | 'onChange'> {
  /** On change handler (called for any select/deselect) */
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  /** The options in the card selector */
  options: CardSelectorItem[]
}

export const CardSelectorGroup = (props: CardSelectorGroupProps) => {
  const {
    options = [],
    disabled,
    testId,
    onChange,
    name,
    multiple,
    itemsContainerClassName,
    ...restProps
  } = props
  const uniqueTestId = useUniqueId(testId, 'checkbox-group-')
  const derivedDisabled = options.every((option) => option.disabled) || disabled
  return (
    <SelectionGroup
      disabled={derivedDisabled}
      multiple={multiple}
      itemsContainerClassName={cx(
        'grid gap-3',
        {
          // HelperText in SelectionGroup uses pt-1, so mb-3 here plus pt-1 add to mt-4
          'mt-4': restProps.label,
          'mb-3': restProps.errorText || restProps.helperText
        },
        itemsContainerClassName
      )}
      {...restProps}
      testId={uniqueTestId}
    >
      {options.map((op) => {
        const { disabled: optionIsDisabled, ...restOpProps } = op
        return (
          <CardSelector
            name={name}
            {...restOpProps}
            disabled={optionIsDisabled || disabled}
            onChange={onChange}
            multiple={multiple}
            key={op.value}
          >
            {op.contents}
          </CardSelector>
        )
      })}
    </SelectionGroup>
  )
}
