import * as React from 'react'

// eslint-disable-next-line
export const useMediaQuery = (mediaQuery?: Breakpoint | string) => {
  if (!mediaQuery) {
    throw new Error('Must pass in a breakpoint')
  }
  const mql = React.useMemo(() => window.matchMedia(mediaQuery), [mediaQuery])
  const [isMatch, setIsMatch] = React.useState(mql.matches)

  React.useEffect(() => {
    const setMatch = (e: MediaQueryListEvent) => setIsMatch(e.matches)
    mql.addEventListener('change', setMatch)

    return () => mql.removeEventListener('change', setMatch)
  }, [mql])

  return isMatch
}
/**
 * @deprecated - use `useScreenSize()` from `@toasttab/use-screen-size`.
 * With the exception of MD, these breakpoints use non-standard widths.
 */
export enum Breakpoint {
  XS = '(max-width: 575px)',
  SM = '(min-width: 576px)',
  SM_ONLY = '(max-width: 767px) and (min-width: 576px)',
  MD = '(min-width: 768px)',
  MD_ONLY = '(max-width: 979px) and (min-width: 768px)',
  LG = '(min-width: 978px)',
  LG_ONLY = '(max-width: 1199px) and (min-width: 978px)',
  XL = '(min-width: 1200px)'
}
