import * as React from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'
import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'

import { SharedSwitchProps, ToggleSwitchSize } from '../types'

const sizes = {
  xs: {
    switch: {
      height: 'h-8'
    },
    button: {
      height: 'h-7',
      width: 'w-7'
    },
    input: {
      top: '-top-2'
    }
  },
  xxs: {
    switch: {
      height: 'h-6'
    },
    button: {
      height: 'h-5',
      width: 'w-5'
    },
    input: {
      top: '-top-3'
    }
  },
  auto: {
    switch: {
      height: 'h-8 md:h-6'
    },
    button: {
      height: 'h-7 md:h-5',
      width: 'w-7 md:w-5'
    },
    input: {
      top: '-top-2 md:-top-3'
    }
  }
}

interface WithAriaLabel {
  'aria-label': string
  'aria-labelledby'?: never
}

interface WithAriaLabelledBy {
  'aria-label'?: never
  'aria-labelledby': string
}

interface ToggleMustBeLabelledWithOneOfThese {
  'aria-label': never
  'aria-labelledby': never
}

export type AriaLabelled =
  | WithAriaLabel
  | WithAriaLabelledBy
  | ToggleMustBeLabelledWithOneOfThese

export type ToggleSwitchProps = SharedSwitchProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'children'> &
  AriaLabelled & {
    /** Whether the switch is in the active state (the right side) */
    isActive?: boolean
    /** Whether the toggle switch should be 60x32px (xs) or 45x24px (xss) */
    size?: ToggleSwitchSize
  }

export const ToggleSwitch = ({
  isActive = false,
  disabled = false,
  onChange,
  containerClassName,
  testId,
  size = 'auto',
  ...props
}: ToggleSwitchProps) => {
  testId = useUniqueId(testId, 'toggle-switch-')
  const screenSize = useScreenSize()

  return (
    <div
      data-testid={testId}
      className={cx(
        containerClassName,
        'group',
        'relative inline-block outline-none',
        'rounded-full focus-within:shadow-focus',
        sizes[size].switch.height,
        {
          'cursor-pointer': !disabled
        }
      )}
      style={{
        width:
          size === 'xxs' || (size === 'auto' && screenSize > ScreenSize.SM)
            ? '2.8125rem'
            : '3.75rem'
      }}
    >
      <div
        className={cx('w-full h-full rounded-full', {
          'bg-gray-30': disabled && !isActive,
          'bg-primary-25': disabled && isActive,
          'bg-gray-50 group-hover:bg-gray-75': !disabled && !isActive,
          'bg-primary-75 group-hover:bg-primary-100': !disabled && isActive
        })}
      >
        <div
          className='absolute h-full'
          style={{
            width: '53.33%',
            left: isActive ? '46.67%' : '0',
            right: isActive ? '0' : 'unset',
            transition: 'left 0.2s ease-in-out'
          }}
        >
          <span
            className={cx(
              'shadow block bg-white rounded-full',
              sizes[size].button.height,
              sizes[size].button.width
            )}
            style={{
              margin: '0.125rem'
            }}
          />
        </div>
        <div className='absolute flex w-full h-full'>
          <div className='h-full select-none' />
          <div className='h-full select-none' />
        </div>
      </div>
      <input
        className={cx(
          'absolute w-full h-12 m-0 opacity-0',
          sizes[size].input.top,
          {
            'cursor-pointer': !disabled
          }
        )}
        type='checkbox'
        checked={isActive}
        aria-checked={isActive}
        // This is for heap and other analytics tools,
        // because the checked property doesn't write through to the DOM attribute
        data-checked={isActive}
        onChange={(e) => !disabled && onChange && onChange(e)}
        disabled={disabled}
        data-testid={`${testId}-input`}
        {...props}
      />
    </div>
  )
}
