import { ApolloClient, InMemoryCache } from '@apollo/client'
import { StrictTypedTypePolicies } from './generated/helpers'

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Customer: {
        keyFields: ['uuid']
      }
    } as StrictTypedTypePolicies
  }),
  uri: '/graphql'
})
