export default {
  dismiss: {
    message: 'Dismiss',
    description: 'Label indicating that a given process has been dismissed'
  },
  error: {
    message: 'Error',
    description: 'Label indicating an error for a given process'
  },
  success: {
    message: 'Success',
    description: 'Label indicating the success of a given process'
  }
}
