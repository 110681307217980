import React, { useEffect, createContext, useContext } from 'react'
import {
  I18next,
  Bundle,
  TranslationLoader,
  TranslationLoaderFn,
  TranslationContextValue
} from '../types'
import { Locale } from '@toasttab/buffet-pui-types'

import useI18nLanguage from './useI18nLanguage'
import useCustomLanguageContextValue from './useCustomLanguageContextValue'

const DEFAULT_LANG = 'en-US'

type Args = {
  spaName: string
  loadingElement?: React.ReactNode
  disableLoadingElement?: boolean
  defaultLanguage?: string
} & (
  | { translationLoader: TranslationLoader }
  | { translationLoaderFn: TranslationLoaderFn }
)

type Props = {
  cdnUrl?: string
  i18n: I18next
  loadingElement?: React.ReactNode
  children: React.ReactNode
}

type TranslationUtilities<T extends Bundle> = {
  /**
   * @deprecated - use BuffetTranslationProvider, since we expect to have a TranslationProvider
   * component in @local/translations package.
   */
  TranslationProvider: (props: Props) => React.ReactElement | null
  BuffetTranslationProvider: (props: Props) => React.ReactElement | null
  useTranslation: () => TranslationContextValue<T>
}

const createTranslationUtilities = <T extends Bundle>(
  args: Args
): TranslationUtilities<T> => {
  const {
    spaName,
    loadingElement: fallbackLoadingElement,
    disableLoadingElement
  } = args

  const getBundle = async (lang: string, cdnUrl: string) => {
    if ('translationLoaderFn' in args) {
      const loader = args.translationLoaderFn({ cdnUrl })

      const resp = await loader(lang)

      return resp.local?.translations || resp.fallback.translations
    } else {
      const resp = await args.translationLoader(lang)

      return resp.local?.translations || resp.fallback.translations
    }
  }

  const cache: Record<string, Promise<Bundle> | undefined> = {}

  type Result =
    | { status: 'success'; bundle: Bundle }
    | { status: 'error'; bundle: undefined }

  const load = async (lang: string, cdnUrl: string): Promise<Result> => {
    try {
      cache[lang] = cache[lang] || getBundle(lang, cdnUrl)
      const bundle = await cache[lang]
      if (!bundle) {
        console.error(`did not receive bundle for lang='${lang}'`)
        return { status: 'error', bundle: undefined }
      }
      // console.log(`received bundle for lang='${lang}'`, bundle)
      return { status: 'success', bundle }
    } catch {
      console.error(`error fetching lang='${lang}'`)
      return { status: 'error', bundle: undefined }
    }
  }

  const useLoadBundle = (lng: string, i18n: I18next, cdnUrl: string) => {
    useEffect(() => {
      const loadBundleFn = async () => {
        const res = await load(lng, cdnUrl)
        if (res.status === 'success') {
          i18n.addResourceBundle(lng, spaName, res.bundle)
        } else {
          console.error('error getting resources bundle')
        }
      }
      if (!i18n.getResourceBundle(lng, spaName)) {
        loadBundleFn()
      }
    }, [i18n, lng, cdnUrl])
  }

  const Context = createContext<TranslationContextValue<T>>({
    t: (str) => str,
    Trans: () => null,
    changeLanguage: (lang: Locale) => {
      console.log(
        `changeLanguage no-op.  attempted to switch languages to '${lang}'`
      )
    },
    language: DEFAULT_LANG,
    isLoading: false,
    isColdLoading: false,
    i18n: {} as any
  })

  const BuffetTranslationProvider = (props: Props) => {
    const {
      cdnUrl: passedCdnUrl,
      i18n,
      loadingElement: passedLoadingElement,
      children
    } = props

    const {
      language, //
      isLoading,
      isColdLoading
    } = useI18nLanguage({ i18n, spaName })

    const cdnUrl = passedCdnUrl || '' // in the future cdnUrl may be available over a context

    // always request the default language
    useLoadBundle(DEFAULT_LANG, i18n, cdnUrl)

    // request i18n's set language
    // (it'll be a cached no-op if language === DEFUALT_LANG)
    useLoadBundle(language, i18n, cdnUrl)

    const contextValue = useCustomLanguageContextValue<T>({
      spaName,
      i18n,
      language,
      isLoading,
      isColdLoading
    })

    // if we have no translations, then we do do not show anything
    // spas which display layout (ex: a nav spa) may want to implement their own
    // guard conditions in order to get layout elements into the DOM immediately
    if (isColdLoading && !disableLoadingElement) {
      return <>{passedLoadingElement ?? fallbackLoadingElement ?? null}</>
    }

    return <Context.Provider value={contextValue}>{children}</Context.Provider>
  }

  const useTranslation = () => useContext(Context)

  return {
    BuffetTranslationProvider,
    TranslationProvider: BuffetTranslationProvider,
    useTranslation
  }
}

export default createTranslationUtilities
