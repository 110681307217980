import { format as dateFnsFormatTz } from 'date-fns-tz'
import { format, Formats, parseISO } from '@toasttab/buffet-pui-date-utilities'
import { isToday } from 'date-fns'

export function formatDateAtTime(date: string) {
  return `${formatDate(date, true)} at ${formatTime(date)}`
}
export function formatDateTime(date: string) {
  return `${formatDate(date)} ${formatTime(date)}`
}

export function formatDate(date: string, useToday: Boolean = false) {
  if (useToday && isToday(parseISO(date))) {
    return 'Today'
  }
  return format(parseISO(date), Formats.date.medium)
}

export function formatTime(date: string) {
  // pretty time zone formatting is not supported yet in buffet-pui-date-utilities
  return dateFnsFormatTz(parseISO(date), 'h:mm a z')
}
