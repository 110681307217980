import * as React from 'react'
import { useState } from 'react'
import { AuditDrawer } from '..'
import { UseGetLastEventResponse } from '@local/api/graphql'
import { useTranslation } from '@local/translations'
import { LoadingWrapper } from '../../LoadingWrapper'
import { formatDateAtTime } from '../../../helpers/dateFormatter'

export interface AuditSummaryProps {
  testId?: string | number
  lastEventResponse: UseGetLastEventResponse
}

/**
 * AuditSummary component
 */
export const AuditSummary = (props: AuditSummaryProps) => {
  const { t } = useTranslation()
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  let datetime = props.lastEventResponse.lastEvent?.datetime
  let user = props.lastEventResponse.lastEvent?.user?.displayName

  return (
    <>
      <div data-testid={props.testId} className='flex flex-col items-end gap-1'>
        <LoadingWrapper isLoading={props.lastEventResponse.loading}>
          {datetime && user && (
            <div className='type-subhead text-secondary font-normal'>
              {t('audit-summary', {
                formattedDateTime: formatDateAtTime(datetime),
                user: user
              })}
            </div>
          )}
        </LoadingWrapper>
        <div
          role='link'
          className='inline-link type-subhead font-semibold mb-5 cursor-pointer'
          onClick={() => setDrawerOpen(true)}
        >
          {t('audit-summary.link')}
        </div>
      </div>
      <AuditDrawer
        isOpen={isDrawerOpen}
        onRequestClose={() => setDrawerOpen(false)}
      />
    </>
  )
}
