import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const Permissions403DesktopIllustration = React.forwardRef(
  function Permissions403DesktopIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '600'}
              height={resizeToContainer ? '100%' : '280'}
              viewBox='0 0 600 280'
              fill='none'
              className='inline-block'
            >
              <g clipPath='url(#permissions-403-desktop_svg__clip0_2674_899)'>
                <path
                  d='M33.6 203.85H6.1c-2 0-3.9.2-5.8.4M34.2 203.25c0-20.4 16.6-37 37-37M70.6 165.75c0-20.4 16.6-37 37-37M145.6 165.75c0-20.4-16.6-37-37-37M549 226.75H422.1M523.1 226.85l-37.1 15.8M448.8 226.85l37.1 15.8M485.6 109.95c34.4 0 62.3 27.6 62.3 62.3H423.2c0-34.7 27.9-62.3 62.4-62.3z'
                  stroke='#E5E5E5'
                  strokeWidth={3}
                  strokeLinecap='round'
                />
                <path
                  d='M486.1 109.95c12.6 0 22.8 27.9 22.8 62.3h-45.6c0-34.4 10.2-62.3 22.8-62.3zM486.7 227.85v45.6M486.7 174.45v53.4M68.3 188.55H116M51.4 240.15h33M143.2 273.15l-26.8-84.1M95.6 272.65l-26.4-82.8M40.5 272.65l26.9-83.6'
                  stroke='#E5E5E5'
                  strokeWidth={3}
                  strokeLinecap='round'
                />
                <path
                  d='M161.1 149.15h173.7M353.1 6.55h69.2M353.1 57.75V6.45'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M334.5 211.45H188.7M334.5 198.75H188.7'
                  stroke='#E5E5E5'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M176 236.75v-86.9'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M213.4 196.55v-28.3h121.4M180.5 52.65v76.4M214.7 52.65v76.4M248.8 52.65v76.4M283.9 52.65v76.4M422.1 109.95H145.5M318.9 52.65v76.4M353.1 52.65v76.4M388.1 52.65v76.4'
                  stroke='#E5E5E5'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M421.8 52.65h-276'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                />
                <path
                  d='M201.4 236.25v-24.8M188.7 211.45v-12.7'
                  stroke='#E5E5E5'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M161.2 236.25v-86.4M334.8 236.25v-86.4M145.5 122.05c0 9.6 7.7 17.4 17.3 17.4 9.5 0 17.3-7.8 17.3-17.4M249.1 122.05c0 9.6 7.7 17.4 17.3 17.4 9.5 0 17.3-7.8 17.3-17.4'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M180 122.05c0 9.6 7.7 17.4 17.3 17.4 9.5 0 17.3-7.8 17.3-17.4M283.7 122.05c0 9.6 7.9 17.4 17.6 17.4 9.7 0 17.6-7.8 17.6-17.4'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M214.6 122.05c0 9.6 7.7 17.4 17.3 17.4 9.5 0 17.3-7.8 17.3-17.4M318.9 122.05c0 9.6 7.7 17.4 17.3 17.4 9.5 0 17.3-7.8 17.3-17.4'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M353.2 122.05c0 9.6 7.7 17.4 17.3 17.4 9.5 0 17.3-7.8 17.3-17.4'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M387.7 122.05c0 9.6 7.7 17.4 17.3 17.4 9.6 0 17.3-7.8 17.3-17.4M421.8 28.75h-276M145.5 28.45v243.2'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M266.3 149.95c0 9.2 7.5 16.7 16.7 16.7s16.7-7.5 16.7-16.7M195 149.95c0 9.2 7.7 16.7 17.3 16.7 9.5 0 17.3-7.5 17.3-16.7M161.1 236.75h172.8'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                />
                <path
                  d='M422.4 6.55v264.8'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M350.1 148.85h58.1M350.1 148.85v124.4M408.2 148.85v124.7M.3 273.55h349.8M408.2 273.55H600M390 184.45h-21.8v18.7H390v-18.7zM385.8 183.65v-2a6.7 6.7 0 00-13.4 0v2'
                  stroke='#FF4C00'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M379.2 196.05a2.3 2.3 0 100-4.6 2.3 2.3 0 000 4.6z'
                  fill='#FF4C00'
                />
                <path
                  d='M61 85.55h27.6c20.4 0 37 16.6 37 37v8.3M61 85.55c0-20.4-16.6-37-37-37M0 49.05c1.9-.3 3.9-.5 5.9-.5h17.6M523.8 91.65c-13.6 0-25.4 7.3-31.9 18.2M524.6 91.05c0-20.4 16.6-37 37-37M600 91.05c0-20.4-16.6-37-37-37M525.8 224.75c0-20.4 16.6-37 37-37M562.2 187.15c0-20.4 16.6-37 37-37'
                  stroke='#E5E5E5'
                  strokeWidth={3}
                  strokeLinecap='round'
                />
                <path
                  d='M394.7 222.65h6.7'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <clipPath id='permissions-403-desktop_svg__clip0_2674_899'>
                  <rect width={600} height={280} fill='#fff' />
                </clipPath>
              </defs>
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
