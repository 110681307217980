import { Locale } from '@toasttab/buffet-pui-types'
import { fetchTranslations } from './fetchTranslations'

export type CdnTranslations<T> = {
  local?: { translations: T }
  fallback: { translations: T }
}

/**
 * cdnTranslationLoaderFactory
 * @returns {CdnTranslations<T>} Returns an object of the given default strings type containing local and fallback translation strings
 */
export function cdnTranslationLoaderFactory<T>({
  cdnUrl,
  namespace,
  defaultStrings,
  defaultLocale = 'en-US'
}: {
  cdnUrl: string
  namespace: string
  defaultStrings: T
  defaultLocale?: Locale
}): (locale: string) => Promise<CdnTranslations<T>> {
  return async (locale: string) => {
    const cdnLocaleUrl = `${cdnUrl}/${namespace}/${locale}.json`
    const responseJSON: { translations: T } =
      defaultLocale === locale ? {} : await fetchTranslations(cdnLocaleUrl)
    return {
      local: responseJSON.translations
        ? { translations: responseJSON.translations }
        : undefined,
      fallback: { translations: defaultStrings }
    }
  }
}
