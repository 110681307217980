import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const Permissions403MobileIllustration = React.forwardRef(
  function Permissions403MobileIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '300'}
              height={resizeToContainer ? '100%' : '180'}
              viewBox='0 0 300 180'
              fill='none'
              className='inline-block'
            >
              <g clipPath='url(#permissions-403-mobile_svg__clip0_2674_904)'>
                <path
                  d='M15.4 151.9V57.4'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M37.5 1.7v20.5M74.9 1.7v20.5M112.4 1.7v20.5M150.6 1.7v20.5M187.9 1.7v20.5M225.1 1.7v20.5M262.6 1.7v20.5'
                  stroke='#E5E5E5'
                  strokeWidth={3}
                  strokeLinejoin='round'
                />
                <path
                  d='M0 56.7h190.7'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M190.4 124.4H31.9M190.4 110.5H31.9M58.9 108.2V77.4h131.9M45.8 151.3v-26.9M31.9 124.4v-13.8'
                  stroke='#E5E5E5'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M190.8 151.3V57.4M74.9 22.2c0 10.4 8.4 18.8 18.7 18.8 10.3 0 18.7-8.4 18.7-18.8M0 22.2C0 32.6 8.4 41 18.7 41c10.3 0 18.7-8.4 18.7-18.8M112.4 22.2c0 10.4 8.6 18.8 19.1 18.8 10.6 0 19.1-8.4 19.1-18.8M37.5 22.2c0 10.4 8.4 18.8 18.7 18.8 10.3 0 18.7-8.4 18.7-18.8M150.6 22.2c0 10.4 8.4 18.8 18.7 18.8 10.3 0 18.7-8.4 18.7-18.8'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M187.7 22.2c0 10.4 8.4 18.8 18.7 18.8 10.3 0 18.7-8.4 18.7-18.8M225.1 22.2c0 10.4 8.4 18.8 18.7 18.8 10.3 0 18.7-8.4 18.7-18.8M262.6 22.2c0 10.4 8.4 18.8 18.7 18.8 10.3 0 18.7-8.4 18.7-18.8'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M116.3 57.6c0 10 8.1 18.1 18.1 18.1 10 0 18.1-8.1 18.1-18.1M38.9 57.6c0 10 8.4 18.1 18.7 18.1 10.4 0 18.7-8.1 18.7-18.1M0 151.9h189.8'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                />
                <path
                  d='M207.4 56.3h63.1M207.4 56.3v122M270.5 56.3v122M251.1 93.9h-24.3v20.8h24.3V93.9zM246.3 93v-2.2c0-4.1-3.3-7.4-7.4-7.4-4.1 0-7.4 3.3-7.4 7.4V93'
                  stroke='#FF4C00'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M238.9 106.9a2.6 2.6 0 100-5.2 2.6 2.6 0 000 5.2z'
                  fill='#FF4C00'
                />
                <path
                  d='M0 178.3h207.4M270.5 178.3H300'
                  stroke='#FF4C00'
                  strokeWidth={3}
                  strokeLinejoin='round'
                />
                <path
                  d='M254.6 130h8'
                  stroke='silver'
                  strokeWidth={3}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <clipPath id='permissions-403-mobile_svg__clip0_2674_904'>
                  <rect width={300} height={180} fill='#fff' />
                </clipPath>
              </defs>
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
