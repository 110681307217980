import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import React, { ReactElement } from 'react'

export const LoadingWrapperModal = ({
  isLoading,
  children
}: {
  isLoading: boolean
  children: ReactElement | Array<ReactElement>
}) => {
  return isLoading ? (
    <div className='flex items-center justify-center w-full h-full'>
      <MerryGoRound />
    </div>
  ) : (
    <>{children}</>
  )
}
