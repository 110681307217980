/**
 * fetchTranslations
 */
export async function fetchTranslations(localeUrl: string) {
  let response
  try {
    response = await fetch(localeUrl)
  } catch (err) {
    console.error('A network error occurred when fetching translations.', err)
    return {}
  }
  if (!response || !response.ok) {
    console.error('An error occurred when fetching translations.')
    if (response && !response.ok) {
      console.error(response.statusText)
    }
    return {}
  }
  return await response.json()
}
