import React, { useMemo } from 'react'
import { Trans as TransI18Next } from 'react-i18next'

import { I18next, Bundle, TOptions, TranslationContextValue } from '../types'

/*

this hook does two things:

1 - build the custom t function and Trans component and the rest of the bundle that is passed down on the context

2 - ensure that that bundle is referentially table to avoid uncessary re-renders of children (specifically, other memo's key off of the t function)

*/

type Args = {
  spaName: string
  i18n: I18next
  language: string
  isLoading: boolean
  isColdLoading: boolean
}

const useCustomLanguageContextValue = <T extends Bundle>(args: Args) => {
  const { spaName, language, i18n, isLoading, isColdLoading } = args

  // value is a referential stale bundle properties to be used in the spa
  return useMemo(
    (): TranslationContextValue<T> => ({
      // to change the language, set it in i18n
      changeLanguage: i18n.changeLanguage,

      // custom t function
      t: (key, val, options?: TOptions) => {
        /*
        Cold loading will only be true if the spa has opted into
        disableLoadingElement === true
        this should only be used for spas like navigation spas,
        where getting layout elements onscreen
        is more important than having localization ready.
  
        In these cases, the spa can implement their own placeholders using
  
        const { isColdLoading } = useTranslation()
        */
        if (isColdLoading) {
          return ' '
        }

        if (typeof val === 'object') {
          // if the value is an object we know that its actual type is TOptions
          const valObj = val as TOptions
          return i18n.t(key, { ns: spaName, ...valObj })
        }

        if (typeof val === 'string') {
          return i18n.t(key, val, { ns: spaName, ...options })
        }

        return i18n.t(key, { ns: spaName, ...options })
      },

      Trans: (transProps) => {
        return (
          <TransI18Next i18n={i18n} ns={spaName} {...transProps}>
            {transProps.children}
          </TransI18Next>
        )
      },
      i18n,
      language,
      isLoading,
      isColdLoading
    }),
    [spaName, i18n, language, isLoading, isColdLoading]
  )
}

export default useCustomLanguageContextValue
