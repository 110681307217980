import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import React, { ReactElement } from 'react'

export const LoadingWrapper = ({
  isLoading,
  children
}: {
  isLoading: boolean
  children: ReactElement | Array<ReactElement>
}) => {
  return isLoading ? (
    <div
      data-testid={'loading-provider'}
      className='fixed top-0 left-0 z-50 block w-full h-full bg-white bg-opacity-50'
    >
      <span
        className='relative block w-0 h-0 mx-auto my-0 top-1/2'
        style={{ top: '50%' }}
      >
        <MerryGoRound size='xxs' />
      </span>
    </div>
  ) : (
    <>{children}</>
  )
}
