import * as React from 'react'
import cx from 'classnames'
import { DoneIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { MutableRefObject, useRef } from 'react'
import { useClassNameWatch } from '@toasttab/use-classname-watcher'

type OmittedInputProps = 'size' | 'type'
type CardSize = 'sm' | 'md' | 'lg'
type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  OmittedInputProps
>

const BASE_STYLES = 'type-default rounded-button w-full block px-4 py-3 m-0'

const stylesBySelectedState: {
  [key: string]: {
    base?: string
    hover?: string
    active?: string
    focus?: string
  }
} = {
  default: {
    base: 'bg-white shadow cursor-pointer',
    hover: 'hover:bg-darken-4',
    active: 'active:bg-darken-4',
    focus: 'focus-within:shadow-selectable-focus'
  },
  selected: {
    base: 'bg-white shadow-selected cursor-pointer',
    hover: 'hover:bg-darken-4',
    active: 'active:bg-darken-4',
    focus: 'focus-within:shadow-selected-focus'
  },
  disabled: {
    base: 'cursor-default bg-gray-0 shadow-none'
  },
  'selected-disabled': {
    base: 'bg-gray-0 shadow-selected-disabled'
  }
}

export interface BaseCardSelectorProps extends InputProps, TestIdentifiable {
  /** True for hiding multi-select checkboxes, false for displaying them */
  hideMultiSelectCheckboxes?: boolean
  /** Deprecated: All sizes will now use a default padding of 12px vertically (as per design guidance) */
  size?: CardSize
  /** Is the selectable item selected? (note that we use "checked" because under the hood we're using a radio button) */
  checked?: InputProps['checked']
  /** Is the selectable item disabled? */
  disabled?: InputProps['disabled']
  /** Additional classes to apply to the outer label */
  className?: string
  /** The name of the group of selectable items (note this corresponds to the name field on the radio buttons) */
  name?: string
  /** The value for the selectable item (corresponds to the value for the corresponding radio button) */
  value?: string | number
  /** The onChange handler */
  onChange?: InputProps['onChange']
  /** The onClick handler (click gets called before change event - use this if you need to do confirmations etc.) */
  onClick?: InputProps['onClick']
  /** True for multi-select (checkbox), false for single select (radio) */
  multiple?: InputProps['multiple']
  /** An optional title, in addition to children */
  title?: string
}

export const BaseCardSelector = ({
  size,
  disabled,
  checked,
  multiple = false,
  hideMultiSelectCheckboxes = false,
  className,
  name,
  value,
  onChange,
  onClick,
  testId,
  title,
  children,
  ...restProps
}: React.PropsWithChildren<BaseCardSelectorProps>) => {
  const selectedState = disabled
    ? checked
      ? 'selected-disabled'
      : 'disabled'
    : checked
    ? 'selected'
    : 'default'

  const {
    base,
    hover = '',
    active = '',
    focus = ''
  } = stylesBySelectedState[selectedState]

  const inputRef =
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>
  const { matchingClassNames } = useClassNameWatch(
    inputRef,
    ['focus-visible'],
    false
  )
  const hasFocusVisible = !!matchingClassNames.length

  return (
    <label
      data-testid={testId}
      className={cx(
        BASE_STYLES,
        base,
        hover,
        active,
        hasFocusVisible && focus,
        'relative',
        {
          'pl-9': multiple && !hideMultiSelectCheckboxes
        },
        className
      )}
    >
      {multiple && !hideMultiSelectCheckboxes && (
        <div
          data-testid={`${testId}-checkbox`}
          className='absolute top-0 left-0'
        >
          {checked ? (
            <div
              data-testid={`${testId}-checkbox-checked`}
              className={cx(
                'text-white',
                'w-6',
                'h-6',
                'border',
                'rounded-button',
                'rounded-bl-none',
                'rounded-tr-none',
                'border-t-0',
                'border-l-0',
                'bg-opacity-100',
                'flex items-center justify-center',
                disabled ? 'bg-gray-50' : 'bg-primary-75',
                disabled ? 'border-gray-50' : 'border-primary-75'
              )}
            >
              <DoneIcon size='xs' accessibility='decorative' />
            </div>
          ) : (
            <div
              data-testid={`${testId}-checkbox-unchecked`}
              // Note: We're offsetting by 1px (via the mt-px and ml-px classes). This is to avoid "dots" where this border
              // overlaps the outer card border. But... We also need the overall size to be 24px so we use a custom style
              // for height and width (instead of using w-6 and h-6)
              style={{
                width: 'calc(1.5rem - 1px)',
                height: 'calc(1.5rem - 1px)'
              }}
              className='mt-px ml-px border border-t-0 border-l-0 rounded-button rounded-tr-none rounded-bl-none border-darken-12'
            />
          )}
        </div>
      )}
      <input
        ref={inputRef}
        data-testid={`${testId}-input`}
        type={multiple ? 'checkbox' : 'radio'}
        value={value}
        checked={checked}
        disabled={disabled}
        name={name}
        onChange={!disabled && onChange ? onChange : undefined}
        onClick={!disabled && onClick ? onClick : undefined}
        className='sr-only'
        {...restProps}
      />
      {title && (
        <div
          className={cx(
            'type-default font-semibold',
            disabled ? 'text-secondary' : 'text-default',
            {
              'mb-1': children
            }
          )}
        >
          {title}
        </div>
      )}
      {children}
    </label>
  )
}

export interface CardSelectorProps extends BaseCardSelectorProps {}

export const CardSelector = ({
  testId = 'card-selector',
  size = 'lg',
  className,
  ...props
}: React.PropsWithChildren<CardSelectorProps>) => (
  <BaseCardSelector
    testId={testId}
    size={size}
    {...props}
    className={cx(className, 'font-normal text-secondary')}
  />
)
