let idCounter = 0

/**
 * This generates a unique ID
 * @return {String} the unique ID
 */
export function generateId(): string {
  return String(idCounter++)
}

/**
 * test a boolean value and write a message if it fails
 * @param {boolean} test a boolean value to test
 * @param {String} message a message to display if the tests fails
 */
export function assert(test: boolean, message: string) {
  if (test) console.warn(message)

  return test
}
