import { graphql } from '../generated/gql'

export const CUSTOMER_SETTINGS = graphql(`
  query payCardCustomerSettings {
    payCardCustomerSettings {
      payCardTipsConfiguration
      payCardPayoutConfiguration
      payCardPayoutEnabled
      payCardAdminSettings {
        payCardAdminPayoutsLocked
      }
    }
  }
`)

export const UPDATE_CUSTOMER_SETTINGS = graphql(`
  mutation updatePayCardCustomerSettings(
    $input: UpdatePayCardCustomerSettingsInput!
  ) {
    updatePayCardCustomerSettings(input: $input) {
      payCardCustomerSettings {
        payCardTipsConfiguration
        payCardPayoutConfiguration
        payCardPayoutEnabled
        payCardAdminSettings {
          payCardAdminPayoutsLocked
        }
      }
    }
  }
`)

export const AUDIT_HISTORY = graphql(`
  query payCardCustomerSettingsAuditHistory {
    payCardCustomerSettingsAuditHistory {
      change {
        messageKey
      }
      datetime
      user {
        displayName
      }
    }
  }
`)

export const LAST_EVENT = graphql(`
  query payCardCustomerSettingsLastEvent {
    payCardCustomerSettingsLastEvent {
      user {
        displayName
      }
      datetime
      change {
        messageKey
      }
    }
  }
`)

export const CUSTOMER_ACCESS = graphql(`
  query simplePayCardCustomerAccess($input: PayCardCustomerAccessInput!) {
    payCardCustomerAccess(input: $input) {
      customerUuid
      businesses {
        restaurants {
          restaurantGuid
          accessLevel
        }
      }
      detachedRestaurants {
        restaurantGuid
        accessLevel
      }
    }
  }
`)
