import React, { FunctionComponent } from 'react'
import { Portal } from '@toasttab/ec-layout'
import { SettingsPageBaseCard } from './SettingsPage/SettingsPageBaseCard'

export const EmployeeCardSettingsEntryPoint: FunctionComponent = () => {
  return (
    <Portal
      id='paycard_settings_goes_here'
      component={<SettingsPageBaseCard />}
      skipRenderIfNotFound={false}
    />
  )
}
