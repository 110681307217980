import * as React from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'
import { ToggleSwitch, ToggleSwitchProps } from '../ToggleSwitch'

export type LabelledToggleSwitchProps = ToggleSwitchProps & {
  /** Optional prefix for the aria-label - e.g. "Takeout enabled"
   * @deprecated use aria-label instead
   */
  ariaLabelPrefix?: string
  /** Labels of the switch in the form of [ 'Inactive label', 'Active label' ]  */
  labels?: Array<string>
}

export const LabelledToggleSwitch = ({
  isActive = false,
  disabled = false,
  // eslint-disable-next-line deprecation/deprecation
  ariaLabelPrefix = '',
  labels = ['Off', 'On'],
  containerClassName,
  testId,
  id,
  name,
  size = 'auto',
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  ...props
}: LabelledToggleSwitchProps) => {
  testId = useUniqueId(testId, 'labelled-toggle-switch-')
  const uniqueId = useUniqueId(id, 'labelled-toggle-switch-')
  const labelBaseStyle = 'font-normal type-subhead m-0'

  return (
    <div
      data-testid={`${testId}-container`}
      className={cx(containerClassName, 'flex flex-row items-center space-x-2')}
    >
      <ToggleSwitch
        isActive={isActive}
        disabled={disabled}
        testId={testId}
        size={size}
        id={uniqueId}
        name={name}
        {...(ariaLabelledBy
          ? { 'aria-labelledby': ariaLabelledBy }
          : { 'aria-label': ariaLabel || ariaLabelPrefix })}
        {...props}
      />
      <div className='flex flex-col justify-center'>
        <label
          htmlFor={uniqueId}
          className={cx(
            labelBaseStyle,
            disabled ? 'text-disabled' : 'text-secondary',
            isActive ? 'h-auto' : 'h-0 invisible'
          )}
          data-testid={`${testId}-active-label`}
          aria-hidden
        >
          {labels[1]}
        </label>
        <label
          htmlFor={uniqueId}
          className={cx(
            labelBaseStyle,
            disabled ? 'text-disabled' : 'text-secondary',
            isActive ? 'h-0 invisible' : 'h-auto'
          )}
          data-testid={`${testId}-inactive-label`}
          aria-hidden
        >
          {labels[0]}
        </label>
      </div>
    </div>
  )
}
