import { useState, useEffect, useMemo } from 'react'
import { I18next } from '../types'

type Args = {
  i18n: I18next
  spaName: string
}

type Langs = {
  language: string
  lastLanguage: string | undefined
}

type Added = Record<string, boolean>

// synchronizes the currently active i18n language to React state
const useI18nLanguage = (args: Args) => {
  const { i18n, spaName } = args

  // track i18n's language as state
  const [langs, setLangs] = useState<Langs>(() => {
    return { language: i18n.language, lastLanguage: undefined }
  })

  const setLanguage = useMemo(() => {
    return (lng: string) => {
      setLangs((prev) => ({ language: lng, lastLanguage: prev.language }))
    }
  }, [setLangs])

  const { language, lastLanguage } = langs

  // subscribe to i18n's change events so we can track their language as state
  useEffect(() => {
    const onChange = (lng: string) => setLanguage(lng)

    i18n.on('languageChanged', onChange)

    return () => i18n.off('languageChanged', onChange)
  }, [i18n, setLanguage])

  // function to check if we have resources loaded for this language
  const hasLoaded = useMemo(() => {
    return (lng: string) => i18n.hasResourceBundle(lng, spaName)
  }, [i18n, spaName])

  // state to track if we have resources loaded
  const [added, setAdded] = useState<Added>(() => {
    return { [language]: hasLoaded(language) }
  })

  // function to check check if a language has been loaded
  const checkLanguage = useMemo(() => {
    return (lng: string) => {
      setAdded((prev) => ({ ...prev, [lng]: hasLoaded(lng) }))
    }
  }, [hasLoaded])

  // when language changes, check to see if it's been loaded
  useEffect(() => checkLanguage(language), [language, checkLanguage])

  // bind to added events and if they are for us, record them in the added state
  useEffect(() => {
    const onUpdate = (lng: string, ns: string) => {
      if (ns === spaName) checkLanguage(lng)
    }

    i18n.store?.on('added', onUpdate)
    i18n.store?.on('removed', onUpdate)

    return () => {
      i18n.store?.off('added', onUpdate)
      i18n.store?.off('removed', onUpdate)
    }
  }, [i18n, spaName, checkLanguage])

  // compute if the current language is loaded
  // this may be useful for showing loading states,
  // but also serves the specific purpose of tripping the useMemo
  // that generates the t()  function
  // to regenerate when the (likely async) resources finally arrive
  const isLoaded = !!added[language]
  const lastLanguageIsLoaded = lastLanguage ? !!added[lastLanguage] : false

  const isLoading = !isLoaded

  const isColdLoading = !isLoaded && !lastLanguageIsLoaded

  return {
    language,
    lastLanguage,
    isLoading,
    isColdLoading,
    isLoaded,
    lastLanguageIsLoaded
  }
}

export default useI18nLanguage
