import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { domElementGetter } from '@toasttab/ec-layout'
import { AppBootstrap } from './app/App'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import './index.css'
import { setupSentry } from '@toasttab/ec-sentry'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-ec-ecs',
  rootComponent: AppBootstrap,
  domElementGetter: domElementGetter.application,
  portalContainers: ['banquetPortalsContainer'],

  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-employee-card-settings-spa'

setupSentry({
  spa: name,
  dsn: 'https://f87cd91d3d6746ca97634348b558f9ec@o37442.ingest.sentry.io/4505280434012160'
})
