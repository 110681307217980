/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An `ISO-8601` compliant Full Date Scalar - e.g. 2022-06-01 */
  Date: { input: any; output: any; }
  /** An `ISO-8601` compliant DateTime Scalar, e.g. 2022-06-01T10:15:00.000 */
  DateTime: { input: any; output: any; }
  /** A signed decimal number, which supports arbitrary precision and is serialized as a string. Example values: "29.99", "29.999". */
  Decimal: { input: any; output: any; }
  HashId: { input: any; output: any; }
  LegacyId: { input: any; output: any; }
  Long: { input: any; output: any; }
  SpaDate: { input: any; output: any; }
  SpaDateTime: { input: any; output: any; }
  join__FieldSet: { input: any; output: any; }
  link__Import: { input: any; output: any; }
};

export type AccessibleCustomersInput = {
  cursor: InputMaybe<Scalars['ID']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type AdditionalDocumentInput = {
  fileName: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
};

export enum Assignee {
  CUSTOMER = 'CUSTOMER',
  ONBOARDING = 'ONBOARDING'
}

export enum AuditAction {
  Checks = 'Checks',
  Opened = 'Opened',
  Posted = 'Posted',
  Postponed = 'Postponed',
  Reset = 'Reset'
}

export type AvailabilityRequest = {
  email: InputMaybe<Scalars['String']['input']>;
  employeeId: InputMaybe<Scalars['ID']['input']>;
  employeeNumber: InputMaybe<Scalars['Int']['input']>;
  posAccessCode: InputMaybe<Scalars['Int']['input']>;
  ssn: InputMaybe<Scalars['String']['input']>;
};

export type BenefitsInput = {
  acaPlan: InputMaybe<Scalars['String']['input']>;
  benefitPlan: InputMaybe<Scalars['String']['input']>;
  tafwPolicy: InputMaybe<Scalars['String']['input']>;
  washingtonLaI: InputMaybe<Scalars['String']['input']>;
  workersComp: InputMaybe<Scalars['String']['input']>;
};

export type BulkDeductionMutation = {
  amount: Scalars['Float']['input'];
  deductionCodeUuid: Scalars['String']['input'];
  employeeUuids: InputMaybe<Array<Scalars['String']['input']>>;
  isPercentage: Scalars['Boolean']['input'];
};

export type BulkEarningMutation = {
  amount: Scalars['Float']['input'];
  checkCodeUuid: Scalars['String']['input'];
  earningCodeUuid: Scalars['String']['input'];
  employeeUuids: Array<Scalars['String']['input']>;
  hours: Scalars['Float']['input'];
  week: Scalars['Int']['input'];
};

export type ChangePayInput = {
  effectiveDate: Scalars['Date']['input'];
  rate: MoneyInput;
};

export type ChangeSalaryAllocationInput = {
  /** Date the new rate should be applied. If more than 1 SalaryAllocation is being updated, effectiveDate must be today's date */
  effectiveDate: Scalars['Date']['input'];
  /** Id of the salary allocation */
  id: Scalars['ID']['input'];
  /** New annual rate for this salary allocation */
  newRate: MoneyInput;
};

export type ConfirmVerificationEmailInput = {
  confirmationKey: Scalars['String']['input'];
};

export type CreateJobTagAssignmentInput = {
  createdByUserGuid: Scalars['ID']['input'];
  jobTagId: Scalars['ID']['input'];
  managementSetId: Scalars['ID']['input'];
  restaurantId: Scalars['ID']['input'];
  restaurantJobId: Scalars['ID']['input'];
  selectedJobTagValue: Scalars['String']['input'];
};

export type CreateJobTagInput = {
  createdByUserGuid: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

/** An `ISO-4217` compliant Currency. */
export enum Currency {
  /** Canadian Dollars (CAD). */
  CAD = 'CAD',
  /** Euro (EUR). */
  EUR = 'EUR',
  /** United Kingdom Pounds (GBP). */
  GBP = 'GBP',
  /** United States Dollars (USD). */
  USD = 'USD'
}

export type CustomDocumentFieldInput = {
  fieldId: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type CustomerInput = {
  locations: Array<LocationInput>;
  uuid: Scalars['ID']['input'];
};

export enum CustomerStatus {
  active = 'active',
  conversion = 'conversion',
  demo_company = 'demo_company',
  inactive = 'inactive',
  new_client_clean_quarter = 'new_client_clean_quarter',
  new_client_mid_quarter = 'new_client_mid_quarter',
  none = 'none',
  prospect = 'prospect',
  suspect = 'suspect',
  unverified = 'unverified'
}

export enum DeliveryMethod {
  Invalid = 'Invalid',
  NotRecorded = 'NotRecorded',
  PayYourOwnWay = 'PayYourOwnWay',
  SelfPrinted = 'SelfPrinted',
  ToastPrinted = 'ToastPrinted'
}

export enum DepositAccountType {
  CHECKING = 'CHECKING',
  MONEYMARKET = 'MONEYMARKET',
  PAYCARD = 'PAYCARD',
  SAVING = 'SAVING'
}

export enum DirectDepositNotificationStatus {
  FAILURE_TO_SEND = 'FAILURE_TO_SEND',
  SUCCESSFULLY_SENT = 'SUCCESSFULLY_SENT'
}

export type DirectDepositPaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export enum DisabilityStatus {
  DOES_NOT_WISH_TO_ANSWER = 'DOES_NOT_WISH_TO_ANSWER',
  NO = 'NO',
  YES = 'YES'
}

export type EeoClassificationInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type EmployeeDocumentUploadRequest = {
  fileName: Scalars['String']['input'];
};

export type EmploymentDetailsInput = {
  defaultEarningsCode: Scalars['ID']['input'];
  eeoClassification: InputMaybe<EeoClassificationInput>;
  employeeNumber: Scalars['Int']['input'];
  employmentTaxType: EmploymentTaxType;
  hireDate: Scalars['Date']['input'];
};

export enum EmploymentStatusType {
  ACTIVE = 'ACTIVE',
  APPLICANT = 'APPLICANT',
  DELETED = 'DELETED',
  DEMO = 'DEMO',
  IMPLEMENTATION = 'IMPLEMENTATION',
  LEAVE_OF_ABSENCE = 'LEAVE_OF_ABSENCE',
  NEVER_EMPLOYED = 'NEVER_EMPLOYED',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  PENDING_ACTIVE = 'PENDING_ACTIVE',
  SEASONAL = 'SEASONAL',
  TERMINATED = 'TERMINATED'
}

export enum EmploymentTaxType {
  K1 = 'K1',
  UNKNOWN = 'UNKNOWN',
  W2 = 'W2',
  _1099 = '_1099'
}

export enum EnumAmountType {
  Currency = 'Currency',
  Percent = 'Percent',
  Undefined = 'Undefined',
  Value = 'Value'
}

export enum Ethnicity {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN = 'ASIAN',
  BLACK_OR_AFRICAN_AMERICAN = 'BLACK_OR_AFRICAN_AMERICAN',
  DO_NOT_WISH_TO_IDENTIFY = 'DO_NOT_WISH_TO_IDENTIFY',
  HAWAIIAN_OR_OTHER_PACIFIC_ISLAND = 'HAWAIIAN_OR_OTHER_PACIFIC_ISLAND',
  HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO',
  TWO_OR_MORE_RACES = 'TWO_OR_MORE_RACES',
  WHITE = 'WHITE'
}

export type FindUserEventsByUserIdInput = {
  fromVersion: InputMaybe<Scalars['Long']['input']>;
  maxNumResults: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};

export type Form8655Input = {
  contact: Scalars['String']['input'];
  fax: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export enum Gender {
  DOES_NOT_WISH_TO_IDENTIFY = 'DOES_NOT_WISH_TO_IDENTIFY',
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export type HourlyEarningInput = {
  earningCodeId: Scalars['ID']['input'];
  hours: Scalars['Decimal']['input'];
  rate: MoneyInput;
};

export type HourlyRetroPayInput = {
  employeeId: Scalars['ID']['input'];
  endDate: Scalars['Date']['input'];
  jobAssignmentId: Scalars['ID']['input'];
  newRate: MoneyInput;
  oldRate: MoneyInput;
  startDate: Scalars['Date']['input'];
};

export enum HumanResourcesPermission {
  CHANGE_UNVERIFIED_USER_EMAIL = 'CHANGE_UNVERIFIED_USER_EMAIL',
  CHANGE_VERIFIED_USER_EMAIL = 'CHANGE_VERIFIED_USER_EMAIL',
  CUSTOMER_CONFIGURATION_EDIT = 'CUSTOMER_CONFIGURATION_EDIT',
  CUSTOMER_CONFIGURATION_VIEW = 'CUSTOMER_CONFIGURATION_VIEW',
  CUSTOMER_JOB_MAPPING_ADMIN_EDIT = 'CUSTOMER_JOB_MAPPING_ADMIN_EDIT',
  CUSTOMER_JOB_MAPPING_ADMIN_VIEW = 'CUSTOMER_JOB_MAPPING_ADMIN_VIEW',
  /** @deprecated replace with EMPLOYEE_EDIT */
  EDIT_MY_PROFILE = 'EDIT_MY_PROFILE',
  EMPLOYEE_ACCESS_ALL = 'EMPLOYEE_ACCESS_ALL',
  EMPLOYEE_ACCESS_ALL_FOR_CUSTOMER = 'EMPLOYEE_ACCESS_ALL_FOR_CUSTOMER',
  EMPLOYEE_EDIT = 'EMPLOYEE_EDIT',
  EMPLOYEE_MAPPING_EDIT = 'EMPLOYEE_MAPPING_EDIT',
  EMPLOYEE_VIEW = 'EMPLOYEE_VIEW',
  EMPLOYMENT_EDIT = 'EMPLOYMENT_EDIT',
  EMPLOYMENT_STATUS_EDIT = 'EMPLOYMENT_STATUS_EDIT',
  EMPLOYMENT_STATUS_VIEW = 'EMPLOYMENT_STATUS_VIEW',
  EMPLOYMENT_VIEW = 'EMPLOYMENT_VIEW',
  JOB_ASSIGNMENT_EDIT = 'JOB_ASSIGNMENT_EDIT',
  JOB_ASSIGNMENT_VIEW = 'JOB_ASSIGNMENT_VIEW',
  MIGRATE_USERS = 'MIGRATE_USERS',
  PAY_CARD_AUDIT_VIEW = 'PAY_CARD_AUDIT_VIEW',
  PAY_CHANGE_HISTORY_VIEW = 'PAY_CHANGE_HISTORY_VIEW',
  PAY_EDIT = 'PAY_EDIT',
  PAY_HISTORY_VIEW = 'PAY_HISTORY_VIEW',
  SSN_EDIT = 'SSN_EDIT',
  SSN_EXISTS_VIEW = 'SSN_EXISTS_VIEW',
  /** @deprecated replace with EMPLOYEE_VIEW */
  VIEW_MY_PROFILE = 'VIEW_MY_PROFILE',
  /** @deprecated removing */
  VIEW_PERMISSIONS = 'VIEW_PERMISSIONS'
}

export type IncompleteDirectDepositInput = {
  direction: SortDirection;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: IncompleteDirectDepositSortColumn;
};

export enum IncompleteDirectDepositSortColumn {
  LAST_CONTACTED = 'LAST_CONTACTED',
  NAME = 'NAME'
}

export enum IntercomClientType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB'
}

export enum IsPayrollPayor {
  no = 'no',
  yesall = 'yesall',
  yesnameonly = 'yesnameonly'
}

export enum IssueSeverity {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN'
}

export enum IssueType {
  ACTIVE_EE_MAPPED_WITH_ARCHIVED_POS = 'ACTIVE_EE_MAPPED_WITH_ARCHIVED_POS',
  JOB_GUID_IS_MALFORMED = 'JOB_GUID_IS_MALFORMED',
  JOB_IS_MISSING_IN_POS = 'JOB_IS_MISSING_IN_POS',
  JOB_NOT_MAPPED = 'JOB_NOT_MAPPED',
  LOCATION_GUID_IS_MALFORMED = 'LOCATION_GUID_IS_MALFORMED',
  LOCATION_IS_MISSING_IN_POS = 'LOCATION_IS_MISSING_IN_POS',
  LOCATION_NOT_MAPPED = 'LOCATION_NOT_MAPPED',
  MAPPED_INTO_DIFFERENT_TOAST_IDENTITY = 'MAPPED_INTO_DIFFERENT_TOAST_IDENTITY',
  PAYROLL_JOBS_MISMATCH_POS_JOBS = 'PAYROLL_JOBS_MISMATCH_POS_JOBS',
  POS_EE_MAPPING_IGNORED = 'POS_EE_MAPPING_IGNORED',
  POS_EE_MAPPING_UNKNOWN_LOCATION_GUID = 'POS_EE_MAPPING_UNKNOWN_LOCATION_GUID',
  RU_COUNTERPART_IS_MISSING_IN_POS = 'RU_COUNTERPART_IS_MISSING_IN_POS',
  UNMAPPED_PAYROLL_SPECIALTY = 'UNMAPPED_PAYROLL_SPECIALTY',
  UNMAPPED_POS_RU = 'UNMAPPED_POS_RU'
}

export type JobInput = {
  restaurantJobGuid: InputMaybe<Scalars['ID']['input']>;
  uuid: Scalars['ID']['input'];
};

export type LevelIdInput = {
  databaseId: Scalars['Int']['input'];
  sequenceId: Scalars['Int']['input'];
};

export type LevelUuidSequence = {
  sequenceId: Scalars['Int']['input'];
  uuid: Scalars['String']['input'];
};

export type LocationInput = {
  restaurantGuid: InputMaybe<Scalars['ID']['input']>;
  uuid: Scalars['ID']['input'];
};

export enum LocationMappingStatusCode {
  NoStatus = 'NoStatus',
  RestaurantGuidConnected = 'RestaurantGuidConnected',
  RestaurantGuidDoubleMapped = 'RestaurantGuidDoubleMapped',
  RestaurantGuidEmpty = 'RestaurantGuidEmpty',
  RestaurantGuidInvalid = 'RestaurantGuidInvalid'
}

export type LumpSumEarningInput = {
  amount: MoneyInput;
  earningCodeId: Scalars['ID']['input'];
};

export type MappingAssignment = {
  locationGuid: Scalars['ID']['input'];
  restaurantUserGuid: Scalars['ID']['input'];
};

export enum MappingCondition {
  MAPPED_INVALID_GUID = 'MAPPED_INVALID_GUID',
  MAPPED_NO_POS_COUNTERPART = 'MAPPED_NO_POS_COUNTERPART',
  MAPPED_USER_MAPPED = 'MAPPED_USER_MAPPED',
  MAPPED_USER_MAPPING_IGNORED = 'MAPPED_USER_MAPPING_IGNORED',
  MAPPED_USER_NOT_MAPPED = 'MAPPED_USER_NOT_MAPPED',
  NOT_MAPPED = 'NOT_MAPPED',
  POS_ONLY = 'POS_ONLY'
}

export enum MaritalStatus {
  DIVORCED = 'DIVORCED',
  HEAD_OF_HOUSEHOLD = 'HEAD_OF_HOUSEHOLD',
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  WIDOW = 'WIDOW'
}

export enum MessageActionType {
  approved = 'approved',
  recorded = 'recorded',
  scheduled = 'scheduled'
}

export type MoneyInput = {
  amount: Scalars['Decimal']['input'];
  currency: Currency;
};

export type NavigableTaxAccountsInput = {
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type NavigationInput = {
  params: InputMaybe<Array<NavigationParam>>;
  path: InputMaybe<Scalars['String']['input']>;
};

export type NavigationParam = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type NewEmployeeInput = {
  additionalDocuments: Array<AdditionalDocumentInput>;
  benefits: BenefitsInput;
  customDocumentFields: Array<CustomDocumentFieldInput>;
  documents: Array<Scalars['ID']['input']>;
  employment: EmploymentDetailsInput;
  jobsAndPay: NewJobsAndPayInput;
  overtimeEligible: Scalars['Boolean']['input'];
  person: PersonInput;
  securityRoleId: Scalars['ID']['input'];
};

export type NewJobAssignmentInput = {
  hourlyRate: InputMaybe<MoneyInput>;
  jobId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};

export type NewJobsAndPayInput = {
  annualSalary: InputMaybe<MoneyInput>;
  jobs: Array<NewJobAssignmentInput>;
  mappings: Array<MappingAssignment>;
  payGroupAssignments: Array<PayGroupAssignmentInput>;
  salaryPerPayPeriod: InputMaybe<MoneyInput>;
  salaryRateOfPay: InputMaybe<MoneyInput>;
};

export enum OnboardingCheckListPermission {
  EDIT_PAYROLL_ONBOARDING = 'EDIT_PAYROLL_ONBOARDING',
  VIEW_PAYROLL_ONBOARDING = 'VIEW_PAYROLL_ONBOARDING'
}

export enum PageType {
  HEADER_ONLY = 'HEADER_ONLY',
  LINK_ONLY = 'LINK_ONLY'
}

export enum PayCardBooleanAccessFactor {
  FALSE = 'FALSE',
  TRUE = 'TRUE',
  UNKNOWN = 'UNKNOWN'
}

export type PayCardCustomerAccessInput = {
  skipCache: Scalars['Boolean']['input'];
};

export type PayCardEmployeeAccessInput = {
  employeeUuid: Scalars['ID']['input'];
  skipCache: Scalars['Boolean']['input'];
};

export enum PayCardEmployeeAccessLevel {
  NONE = 'NONE',
  PAYCARD = 'PAYCARD',
  PAYOUTS = 'PAYOUTS',
  SIGNUP = 'SIGNUP'
}

export enum PayCardEmployerAccessFactors {
  ENROLLMENT_FEATURE_FLAG = 'ENROLLMENT_FEATURE_FLAG',
  FEIN_IN_PAYROLL = 'FEIN_IN_PAYROLL',
  HAS_COMPLETED_CONTRACT = 'HAS_COMPLETED_CONTRACT',
  PAYOUT_ENTITLEMENT = 'PAYOUT_ENTITLEMENT',
  PAY_CARD_ENTITLEMENT = 'PAY_CARD_ENTITLEMENT',
  SDP_FEATURE_FLAG = 'SDP_FEATURE_FLAG'
}

export enum PayCardEmployerAccessLevel {
  EMPLOYEE_PAYOUTS = 'EMPLOYEE_PAYOUTS',
  EMPLOYEE_SIGNUP = 'EMPLOYEE_SIGNUP',
  NONE = 'NONE',
  ONBOARDING = 'ONBOARDING'
}

export enum PayCardPayoutConfiguration {
  TIPS_ONLY = 'TIPS_ONLY',
  WAGES_AND_TIPS = 'WAGES_AND_TIPS',
  WAGES_ONLY = 'WAGES_ONLY'
}

export enum PayCardRestaurantAccessLevel {
  NONE = 'NONE',
  PAYOUTS = 'PAYOUTS',
  SIGNUP = 'SIGNUP'
}

export enum PayCardTipsConfiguration {
  TIPS_ALL = 'TIPS_ALL',
  TIPS_WITH_WITHHOLDING = 'TIPS_WITH_WITHHOLDING'
}

export enum PayChangeEventStatus {
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  ERROR_ACKNOWLEDGED = 'ERROR_ACKNOWLEDGED',
  NEW_JOB = 'NEW_JOB',
  SCHEDULED = 'SCHEDULED',
  UPDATED = 'UPDATED'
}

export enum PayChangeLogSortField {
  CREATE_DATE = 'CREATE_DATE'
}

export type PayChangeLogSortInput = {
  /** Whether to sort in ascending or descending order for this field */
  direction: SortDirection;
  /** The field by which to sort the pay change log events */
  sortField: PayChangeLogSortField;
};

export enum PayFrequency {
  ANNUAL = 'ANNUAL',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  NONE = 'NONE',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUAL = 'SEMIANNUAL',
  SEMIMONTHLY = 'SEMIMONTHLY',
  WEEKLY = 'WEEKLY'
}

export type PayGroupAssignmentInput = {
  checkCodeId: Scalars['ID']['input'];
  locationIds: Array<Scalars['ID']['input']>;
  payGroupId: Scalars['ID']['input'];
  positionId: InputMaybe<Scalars['ID']['input']>;
  workTaxLocationId: Scalars['ID']['input'];
};

export type PayHistoryFilters = {
  feinId: InputMaybe<Scalars['Int']['input']>;
  from: InputMaybe<Scalars['SpaDateTime']['input']>;
  to: InputMaybe<Scalars['SpaDateTime']['input']>;
};

export enum PayHistoryReportType {
  DETAIL = 'DETAIL',
  SUMMARY = 'SUMMARY'
}

export enum PayInterval {
  ANNUAL = 'ANNUAL',
  HOUR = 'HOUR'
}

export enum PayrollCustomerUserSource {
  DB = 'DB',
  DEX = 'DEX'
}

export type PayrollCustomerUsersInput = {
  source: PayrollCustomerUserSource;
  toastIdentityGuid: InputMaybe<Scalars['ID']['input']>;
  userId: InputMaybe<Scalars['ID']['input']>;
  username: InputMaybe<Scalars['String']['input']>;
};

export enum PayrollEmploymentStatus {
  ACTIVE = 'ACTIVE',
  LEAVE_OF_ABSENCE = 'LEAVE_OF_ABSENCE',
  OTHER = 'OTHER',
  TERMINATED = 'TERMINATED'
}

export enum PayrollPermission {
  EDIT_PAYROLL = 'EDIT_PAYROLL',
  EDIT_TAX_CENTER = 'EDIT_TAX_CENTER',
  VIEW_PAYROLL = 'VIEW_PAYROLL',
  VIEW_TAX_CENTER = 'VIEW_TAX_CENTER'
}

export enum PayrollStatus {
  calculated = 'calculated',
  created = 'created',
  editing = 'editing',
  generated_ach = 'generated_ach',
  import = 'import',
  opened = 'opened',
  posted = 'posted',
  posting = 'posting',
  processing = 'processing',
  scheduled = 'scheduled',
  scheduling = 'scheduling',
  unknown = 'unknown',
  verified = 'verified'
}

export enum PayrollStatusV2 {
  CALCULATED = 'CALCULATED',
  CREATED = 'CREATED',
  EDITING = 'EDITING',
  EMPLOYEE_MAINTENANCE = 'EMPLOYEE_MAINTENANCE',
  G2N = 'G2N',
  GENERATED_ACH = 'GENERATED_ACH',
  IMPORT = 'IMPORT',
  OPENED = 'OPENED',
  POSTED = 'POSTED',
  POSTING = 'POSTING',
  POST_PAYROLL = 'POST_PAYROLL',
  PROCESSING = 'PROCESSING',
  PR_MAINTENANCE = 'PR_MAINTENANCE',
  REPORTS = 'REPORTS',
  SCHEDULED = 'SCHEDULED',
  SCHEDULING = 'SCHEDULING',
  SPREADSHEET = 'SPREADSHEET',
  STATUS_CHANGES = 'STATUS_CHANGES',
  UNKNOWN = 'UNKNOWN',
  VACATION = 'VACATION',
  VERIFIED = 'VERIFIED'
}

export enum PendingPayChangeStatus {
  APPLIED = 'APPLIED',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  ERROR_ACKNOWLEDGED = 'ERROR_ACKNOWLEDGED',
  PENDING = 'PENDING'
}

export type PendingPayChangeStatusInput = {
  pendingPayChangeId: Scalars['ID']['input'];
  pendingPayChangeStatus: PendingPayChangeStatus;
};

export type PersonInput = {
  chosenName: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  posAccessCode: InputMaybe<Scalars['Int']['input']>;
  ssn: InputMaybe<Scalars['String']['input']>;
};

export type PosAvailabilityRequest = {
  exportIds: Array<Scalars['ID']['input']>;
  posAccessCode: Scalars['Int']['input'];
};

export enum PostDeadline {
  DayOfPayDate = 'DayOfPayDate',
  FiveDaysBefore = 'FiveDaysBefore',
  FourDaysBefore = 'FourDaysBefore',
  NotAllowedToPost = 'NotAllowedToPost',
  OneDayBefore = 'OneDayBefore',
  ThreeDaysBefore = 'ThreeDaysBefore',
  TwoDaysBefore = 'TwoDaysBefore'
}

export enum PreviousProviderPayrollType {
  current_quarter_prior = 'current_quarter_prior',
  none = 'none',
  parallel = 'parallel'
}

export type RehireEmployeeNoChangesInput = {
  employeeId: Scalars['ID']['input'];
  rehireDate: Scalars['Date']['input'];
};

export type RequiredDocumentsRequest = {
  hireDate: Scalars['Date']['input'];
  securityRoleId: Scalars['ID']['input'];
  workTaxLocationIds: Array<Scalars['ID']['input']>;
};

export enum ResponseStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export enum RestaurantJobStatusCode {
  Available = 'Available',
  /** Restaurant job does not exist */
  Deleted = 'Deleted'
}

export type SalaryRetroPayInput = {
  employeeId: Scalars['ID']['input'];
  endDate: Scalars['Date']['input'];
  newRate: MoneyInput;
  oldRate: MoneyInput;
  startDate: Scalars['Date']['input'];
};

export enum SalaryType {
  commission = 'commission',
  draw = 'draw',
  hourly = 'hourly',
  salary = 'salary',
  ten99 = 'ten99',
  unknown = 'unknown',
  w2 = 'w2'
}

export type SaveDeduction = {
  amount: Scalars['Float']['input'];
  checkCodeUuid: Scalars['String']['input'];
  deductionCodeUuid: Scalars['String']['input'];
  employeeUuid: Scalars['String']['input'];
  isPercentage: Scalars['Boolean']['input'];
  paystub: Scalars['Int']['input'];
};

export type SaveEarning = {
  amount: Scalars['Float']['input'];
  baseRate: Scalars['Float']['input'];
  checkCodeUuid: Scalars['String']['input'];
  earningCodeUuid: Scalars['String']['input'];
  employeeUuid: Scalars['String']['input'];
  hours: Scalars['Float']['input'];
  levels: InputMaybe<Array<LevelUuidSequence>>;
  paystub: Scalars['Int']['input'];
  positionUuid: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
  week: Scalars['Int']['input'];
};

export type SectionsInput = {
  name: InputMaybe<Scalars['String']['input']>;
  params: InputMaybe<Array<NavigationParam>>;
};

export enum SecurityRole {
  APPLICANT = 'APPLICANT',
  CUSTOM = 'CUSTOM',
  CUSTOMER = 'CUSTOMER',
  EMPLOYEE = 'EMPLOYEE',
  FIRED = 'FIRED',
  HR_EXECUTIVE = 'HR_EXECUTIVE',
  HR_PLUS = 'HR_PLUS',
  MANAGER = 'MANAGER',
  NEW_HIRE = 'NEW_HIRE',
  PAYROLL = 'PAYROLL',
  PAYROLL_OPERATIONS = 'PAYROLL_OPERATIONS',
  SUPERUSER = 'SUPERUSER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SUPER_ADMIN_LIGHT = 'SUPER_ADMIN_LIGHT'
}

export type SendVerificationEmailInput = {
  idempotencyKey: Scalars['String']['input'];
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SplitDepositType {
  DOLLAR = 'DOLLAR',
  PERCENTAGE = 'PERCENTAGE'
}

export enum TaskReopenColumn {
  Account = 'Account',
  Fein = 'Fein',
  State = 'State'
}

export enum TaskReopenErrorType {
  Invalid = 'Invalid',
  Missing = 'Missing',
  NotFound = 'NotFound',
  Other = 'Other'
}

export enum TaskStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  LOCKED = 'LOCKED',
  NOT_STARTED = 'NOT_STARTED',
  ON_HOLD = 'ON_HOLD',
  SKIPPED = 'SKIPPED'
}

export type TasksRow = {
  account: Scalars['String']['input'];
  key: Scalars['String']['input'];
  state: Scalars['String']['input'];
  tin: Scalars['String']['input'];
};

export enum TaxPrepChecklistStatus {
  /**  Tax Status is in progress based one the internal tasks completion, relevant when users do not have access to see status across all tasks  */
  IN_PROGRESS = 'IN_PROGRESS',
  /**  Loading state, background-job running for tasks creation considerations  */
  LOADING = 'LOADING',
  /**  Tax Status is COMPLETED based one the internal tasks completion, relevant when users do not have access to see status across all tasks  */
  READY = 'READY',
  /**  Server is not available, race conditions events, etc  */
  UNAVAILABLE = 'UNAVAILABLE'
}

export enum TaxPrepQuarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export enum TaxTaskType {
  TAXCENTER_FORM_8655 = 'TAXCENTER_FORM_8655',
  TAXCENTER_MISSING_SSN = 'TAXCENTER_MISSING_SSN',
  TAXCENTER_PREVIOUS_PROVIDER = 'TAXCENTER_PREVIOUS_PROVIDER',
  TAXCENTER_STATE_PFML_TPA = 'TAXCENTER_STATE_PFML_TPA',
  TAXCENTER_STATE_SIT_ACCOUNT = 'TAXCENTER_STATE_SIT_ACCOUNT',
  TAXCENTER_STATE_SIT_FREQUENCY = 'TAXCENTER_STATE_SIT_FREQUENCY',
  TAXCENTER_STATE_SIT_TPA = 'TAXCENTER_STATE_SIT_TPA',
  TAXCENTER_STATE_SUTA_ACCOUNT = 'TAXCENTER_STATE_SUTA_ACCOUNT',
  TAXCENTER_STATE_SUTA_RATE = 'TAXCENTER_STATE_SUTA_RATE',
  TAXCENTER_STATE_SUTA_TPA = 'TAXCENTER_STATE_SUTA_TPA'
}

export enum ThirdPartyBenefitType {
  DENTAL = 'DENTAL',
  LIFE = 'LIFE',
  MEDICAL = 'MEDICAL',
  VISION = 'VISION'
}

export enum TimeSheetStatus {
  approved = 'approved',
  deleted = 'deleted',
  denied = 'denied',
  open = 'open',
  pending_approval = 'pending_approval',
  returned = 'returned',
  unknown = 'unknown'
}

export enum TimeSheetStatusV2 {
  approved = 'approved',
  deleted = 'deleted',
  denied = 'denied',
  open = 'open',
  pending_approval = 'pending_approval',
  returned = 'returned',
  unknown = 'unknown'
}

export enum TimeSheetTiedToPayroll {
  all = 'all',
  no = 'no',
  ot = 'ot',
  tafwot = 'tafwot'
}

export enum TipsIntegrationSettings {
  NONE = 'NONE',
  NORMAL = 'NORMAL',
  POOLED = 'POOLED'
}

export enum TipsReadyStatus {
  inProgress = 'inProgress',
  noTips = 'noTips',
  notMissingTips = 'notMissingTips',
  ready = 'ready'
}

export enum TipsType {
  none = 'none',
  normal = 'normal',
  pooled = 'pooled'
}

export type UpdatePayCardAdminSettingsInput = {
  payCardAdminPayoutsLocked: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePayCardCustomerSettingsInput = {
  payCardAdminSettings: InputMaybe<UpdatePayCardAdminSettingsInput>;
  payCardPayoutConfiguration: InputMaybe<PayCardPayoutConfiguration>;
  payCardPayoutEnabled: InputMaybe<Scalars['Boolean']['input']>;
  payCardTipsConfiguration: InputMaybe<PayCardTipsConfiguration>;
};

export enum UserMigrationDefaultEmailSource {
  NEW = 'NEW',
  PAYROLL = 'PAYROLL',
  TOAST = 'TOAST'
}

export enum UserMigrationToastAccountType {
  CREATED = 'CREATED',
  EXISTING = 'EXISTING',
  MAPPED = 'MAPPED'
}

export enum UserType {
  admin = 'admin',
  customer = 'customer'
}

export enum VeteranStatus {
  ACTIVE_WAR_TIME_OR_CAMPAIGN_BADGE_VETERAN = 'ACTIVE_WAR_TIME_OR_CAMPAIGN_BADGE_VETERAN',
  ARMED_FORCES_SERVICE_MEDAL_VETERAN = 'ARMED_FORCES_SERVICE_MEDAL_VETERAN',
  DISABLED_VETERAN = 'DISABLED_VETERAN',
  NOT_A_VETERAN = 'NOT_A_VETERAN',
  RECENTLY_SEPARATED_VETERAN = 'RECENTLY_SEPARATED_VETERAN',
  SPECIAL_DISABLED_VETERAN = 'SPECIAL_DISABLED_VETERAN',
  VETERAN = 'VETERAN',
  VIETNAM_ERA_VETERAN = 'VIETNAM_ERA_VETERAN'
}

export enum W2EmployeeSetting {
  ELECTRONIC = 'ELECTRONIC',
  PAPER = 'PAPER'
}

export enum W2PrintingStatus {
  SELF_PRINTED = 'SELF_PRINTED',
  TOAST_PRINTED = 'TOAST_PRINTED'
}

export type W2SettingsInput = {
  printingStatus: W2PrintingStatus;
  shippingAddress: InputMaybe<W2ShippingAddressInput>;
};

export type W2ShippingAddressInput = {
  businessName: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: InputMaybe<Scalars['String']['input']>;
  line1: Scalars['String']['input'];
  line2: Scalars['String']['input'];
  recipientName: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export enum Join__Graph {
  CUSTOMER = 'CUSTOMER',
  EC_HUMAN_RESOURCES = 'EC_HUMAN_RESOURCES',
  EC_JOBS = 'EC_JOBS',
  EC_PAYROLL = 'EC_PAYROLL',
  EC_PAYROLL_ONBOARDING = 'EC_PAYROLL_ONBOARDING',
  EC_PAYROLL_OPERATIONS = 'EC_PAYROLL_OPERATIONS',
  EC_WEB = 'EC_WEB',
  NAVIGATION = 'NAVIGATION',
  PAYROLL = 'PAYROLL',
  PAYROLL_BENEFITS = 'PAYROLL_BENEFITS',
  SDP = 'SDP'
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  EXECUTION = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  SECURITY = 'SECURITY'
}

export type PayCardCustomerSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type PayCardCustomerSettingsQuery = { __typename?: 'Query', payCardCustomerSettings: { __typename?: 'PayCardCustomerSettings', payCardTipsConfiguration: PayCardTipsConfiguration | null, payCardPayoutConfiguration: PayCardPayoutConfiguration | null, payCardPayoutEnabled: boolean | null, payCardAdminSettings: { __typename?: 'PayCardAdminSettings', payCardAdminPayoutsLocked: boolean | null } | null } };

export type UpdatePayCardCustomerSettingsMutationVariables = Exact<{
  input: UpdatePayCardCustomerSettingsInput;
}>;


export type UpdatePayCardCustomerSettingsMutation = { __typename?: 'Mutation', updatePayCardCustomerSettings: { __typename?: 'UpdatePayCardCustomerSettingsResponse', payCardCustomerSettings: { __typename?: 'PayCardCustomerSettings', payCardTipsConfiguration: PayCardTipsConfiguration | null, payCardPayoutConfiguration: PayCardPayoutConfiguration | null, payCardPayoutEnabled: boolean | null, payCardAdminSettings: { __typename?: 'PayCardAdminSettings', payCardAdminPayoutsLocked: boolean | null } | null } } };

export type PayCardCustomerSettingsAuditHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type PayCardCustomerSettingsAuditHistoryQuery = { __typename?: 'Query', payCardCustomerSettingsAuditHistory: Array<{ __typename?: 'PayCardCustomerSettingsAuditEntry', datetime: any, change: { __typename?: 'PayCardCustomerSettingsAuditHistoryChangeContent', messageKey: string }, user: { __typename?: 'PayCardCustomerSettingsAuditUser', displayName: string } }> };

export type PayCardCustomerSettingsLastEventQueryVariables = Exact<{ [key: string]: never; }>;


export type PayCardCustomerSettingsLastEventQuery = { __typename?: 'Query', payCardCustomerSettingsLastEvent: { __typename?: 'PayCardCustomerSettingsAuditEntry', datetime: any, user: { __typename?: 'PayCardCustomerSettingsAuditUser', displayName: string }, change: { __typename?: 'PayCardCustomerSettingsAuditHistoryChangeContent', messageKey: string } } | null };

export type SimplePayCardCustomerAccessQueryVariables = Exact<{
  input: PayCardCustomerAccessInput;
}>;


export type SimplePayCardCustomerAccessQuery = { __typename?: 'Query', payCardCustomerAccess: { __typename?: 'PayCardCustomerAccess', customerUuid: string, businesses: Array<{ __typename?: 'PayCardBusinessAccess', restaurants: Array<{ __typename?: 'PayCardRestaurantAccess', restaurantGuid: string, accessLevel: PayCardEmployerAccessLevel }> }>, detachedRestaurants: Array<{ __typename?: 'PayCardRestaurantAccess', restaurantGuid: string, accessLevel: PayCardEmployerAccessLevel }> } };


export const PayCardCustomerSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"payCardCustomerSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payCardCustomerSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payCardTipsConfiguration"}},{"kind":"Field","name":{"kind":"Name","value":"payCardPayoutConfiguration"}},{"kind":"Field","name":{"kind":"Name","value":"payCardPayoutEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"payCardAdminSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payCardAdminPayoutsLocked"}}]}}]}}]}}]} as unknown as DocumentNode<PayCardCustomerSettingsQuery, PayCardCustomerSettingsQueryVariables>;
export const UpdatePayCardCustomerSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updatePayCardCustomerSettings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePayCardCustomerSettingsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePayCardCustomerSettings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payCardCustomerSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payCardTipsConfiguration"}},{"kind":"Field","name":{"kind":"Name","value":"payCardPayoutConfiguration"}},{"kind":"Field","name":{"kind":"Name","value":"payCardPayoutEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"payCardAdminSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payCardAdminPayoutsLocked"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UpdatePayCardCustomerSettingsMutation, UpdatePayCardCustomerSettingsMutationVariables>;
export const PayCardCustomerSettingsAuditHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"payCardCustomerSettingsAuditHistory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payCardCustomerSettingsAuditHistory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"change"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messageKey"}}]}},{"kind":"Field","name":{"kind":"Name","value":"datetime"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]}}]}}]} as unknown as DocumentNode<PayCardCustomerSettingsAuditHistoryQuery, PayCardCustomerSettingsAuditHistoryQueryVariables>;
export const PayCardCustomerSettingsLastEventDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"payCardCustomerSettingsLastEvent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payCardCustomerSettingsLastEvent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"datetime"}},{"kind":"Field","name":{"kind":"Name","value":"change"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messageKey"}}]}}]}}]}}]} as unknown as DocumentNode<PayCardCustomerSettingsLastEventQuery, PayCardCustomerSettingsLastEventQueryVariables>;
export const SimplePayCardCustomerAccessDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"simplePayCardCustomerAccess"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PayCardCustomerAccessInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payCardCustomerAccess"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customerUuid"}},{"kind":"Field","name":{"kind":"Name","value":"businesses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"restaurants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"restaurantGuid"}},{"kind":"Field","name":{"kind":"Name","value":"accessLevel"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"detachedRestaurants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"restaurantGuid"}},{"kind":"Field","name":{"kind":"Name","value":"accessLevel"}}]}}]}}]}}]} as unknown as DocumentNode<SimplePayCardCustomerAccessQuery, SimplePayCardCustomerAccessQueryVariables>;