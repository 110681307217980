import * as React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { Error500DesktopIllustration } from '@toasttab/buffet-pui-illustrations'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useUser } from '@toasttab/ec-session'

import { TranslationProvider, useTranslation } from '@local/translations'
import { client } from '@local/api/client'

import { EntryRoute } from './routes/entry'

//tell the router that it's ok to load anywhere but not do anything
const wildcard = { path: '*', element: null }

//this is the whole app. the routes do 99% of the work
const App = () => {
  const { t } = useTranslation()
  const user = useUser()
  const routes = useRoutes([EntryRoute, wildcard])

  if (
    user.isUserHrPlusOrDerivative ||
    user.isPureUserHRPlusOrAbove ||
    user.isUserSuperAdminLight ||
    user.isUserSuperAdmin
  ) {
    return routes
  }
  return (
    <div className='flex flex-col items-center space-y-8'>
      <Error500DesktopIllustration />
      <div className='text-center type-headline-3'>{t('no-access.error')}</div>
    </div>
  )
}

/**
 * This is the full application
 */
const AppBootstrap = () => {
  return (
    <React.Suspense
      fallback={
        <div>
          <MerryGoRound className='mx-auto mt-7' />
        </div>
      }
    >
      <ApolloProvider client={client}>
        <BrowserRouter>
          <SnackBarProvider>
            <TranslationProvider>
              <App />
            </TranslationProvider>
          </SnackBarProvider>
        </BrowserRouter>
      </ApolloProvider>
    </React.Suspense>
  )
}

export { App, AppBootstrap }
