import React, { AnchorHTMLAttributes, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'

interface AuxProps {
  pathname: string
  isExternal: Boolean
  isNewTab?: Boolean
  children: React.ReactNode
}

type SmartLinkProperties = HTMLAttributes<HTMLElement> &
  AuxProps &
  AnchorHTMLAttributes<HTMLElement>

export const SmartLink = ({
  pathname,
  isExternal,
  isNewTab = false,
  children,
  ...rest
}: SmartLinkProperties) => {
  return isExternal ? (
    // this error shows up because _bland and noreferrer need to be used in tandem and eslint can't
    // figure out that they will be
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={pathname}
      target={isNewTab ? '_blank' : undefined}
      rel={isNewTab ? 'noopener noreferrer' : undefined}
      {...rest}
    >
      {children}
    </a>
  ) : (
    <Link to={pathname} {...rest}>
      {children}
    </Link>
  )
}
