export const ALL_TIPS_MESSAGE_KEY =
  'customer_settings.tips_withholding_delta_tips_all'
export const TIPS_WITH_WITHHOLDING_MESSAGE_KEY =
  'customer_settings.tips_withholding_delta_tips_with_withholding'
export const PAYOUT_CONFIGURATION_DELTA_WAGES_AND_TIPS =
  'customer_settings.payout_configuration_delta_wages_and_tips'
export const PAYOUT_CONFIGURATION_DELTA_WAGES_ONLY =
  'customer_settings.payout_configuration_delta_wages_only'
export const PAYOUT_CONFIGURATION_DELTA_TIPS_ONLY =
  'customer_settings.payout_configuration_delta_tips_only'
export const PAYOUT_ENABLED_DELTA_ENABLED =
  'customer_settings.payout_delta_enabled'
export const PAYOUT_ENABLED_DELTA_DISABLED =
  'customer_settings.payout_delta_disabled'
export const ADMIN_PAYOUTS_LOCKED_DELTA_UNLOCKED =
  'customer_settings.admin_payouts_delta_unlocked'
export const ADMIN_PAYOUTS_LOCKED_DELTA_LOCKED =
  'customer_settings.admin_payouts_delta_locked'

const CHANGED_PAYOUT = 'changed PayOut settings to'
const CHANGED_ACCESS = 'turned access to Toast PayOuts'

export function mapMessageKeyToDescription(key: string) {
  switch (key) {
    case ALL_TIPS_MESSAGE_KEY: {
      return [CHANGED_PAYOUT, 'All Tips.']
    }
    case TIPS_WITH_WITHHOLDING_MESSAGE_KEY: {
      return [CHANGED_PAYOUT, 'adjust tip offers.']
    }
    case PAYOUT_CONFIGURATION_DELTA_WAGES_AND_TIPS: {
      return [CHANGED_PAYOUT, 'Wages and tips.']
    }
    case PAYOUT_CONFIGURATION_DELTA_WAGES_ONLY: {
      return [CHANGED_PAYOUT, 'Wages only.']
    }
    case PAYOUT_CONFIGURATION_DELTA_TIPS_ONLY: {
      return [CHANGED_PAYOUT, 'Tips only.']
    }
    case PAYOUT_ENABLED_DELTA_ENABLED: {
      return [CHANGED_ACCESS, 'on.']
    }
    case PAYOUT_ENABLED_DELTA_DISABLED: {
      return [CHANGED_ACCESS, 'off.']
    }
    case ADMIN_PAYOUTS_LOCKED_DELTA_UNLOCKED: {
      return [CHANGED_ACCESS, 'on.']
    }
    case ADMIN_PAYOUTS_LOCKED_DELTA_LOCKED: {
      return [CHANGED_ACCESS, 'off.']
    }
    default: {
      break
    }
  }
}
