import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import React, { ReactElement } from 'react'
import {
  isSdpGraphQLError,
  SdpGraphQLError,
  SdpGraphQLErrorCode
} from '../api/graphql/sdpGraphqlError'
import { Maybe } from '@local/api/generated/gql/graphql'
import { ErrorPage403, ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { SettingsEmptyState } from './SettingsEmptyState'

export const LoadingErrorWrapper = ({
  isLoading,
  error,
  children
}: {
  isLoading: boolean
  error: Maybe<SdpGraphQLError | Error | Boolean>
  children: ReactElement | Array<ReactElement>
}) => {
  return isLoading ? (
    <div
      data-testid={'loading-provider'}
      className='fixed top-0 left-0 z-50 block w-full h-full bg-white bg-opacity-50'
    >
      <span
        className='relative block w-0 h-0 mx-auto my-0 top-1/2'
        style={{ top: '50%' }}
      >
        <MerryGoRound size='xxs' />
      </span>
    </div>
  ) : !!error ? (
    isSdpGraphQLError(error) ? (
      ErrorPageMap.get(error.sdpErrorCode) || <ErrorPage500 />
    ) : (
      <ErrorPage500 />
    )
  ) : (
    <>{children}</>
  )
}

const ErrorPageMap: Map<SdpGraphQLErrorCode, React.ReactElement> = new Map()
ErrorPageMap.set(SdpGraphQLErrorCode.INTERNAL_SERVER_ERROR, <ErrorPage500 />)
ErrorPageMap.set(
  SdpGraphQLErrorCode.RESTAURANT_FORBIDDEN,
  <SettingsEmptyState />
)
ErrorPageMap.set(SdpGraphQLErrorCode.USER_FORBIDDEN, <ErrorPage403 />)
